import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Page from './page/page-layout'
import Layout from '../layout'
import GoHome from '../gohome.js'

const Contact = (props) => {
  const lang = props.pageContext.lang

  const Info = styled.div`
    width: 16vw;
    margin: 60px auto 20px auto;
    padding-bottom: 30px;
    font-weight: 300;
  `
  const SideText = styled.p`
    font-size: 1rem;
    font-family: 'Nunito';
    margin-inline-start: auto;
  `
  const side = (
    <Info>
    </Info>
  )

  const Header = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  `
  const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    color: black;
    flex: 3;
    margin: 0;
    z-index: 100;
    position: relative;
    font-size: 2.5rem;
    @media (min-width: 1400px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1600px) {
      font-size: 3.4rem;
    }
    @media (max-width: 1200px) {
      font-size: 2.3rem;
    }
    @media (max-width: 1100px) {
      font-size: 2.2rem;
      flex-direction: column;
    }
  `
  const Content = styled.div`
    flex: 1;
    flex-grow: unset;
    line-height: 170%;
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    @media (min-width: 1400px) {
      font-size: 1.4rem;
    }
    @media (min-width: 1600px) {
      font-size: 1.7rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1100px) {
      font-size: 1rem;
      flex-direction: column;
    }
    @media (max-width: 700px) {
      line-height: 130%;
    }
  `
  const New = styled.dl`
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.1vw !important;
  `
  const Date = styled.dt`
    @media (max-width: 700px) {
      font-size: 11px !important;
    }
  `
  const Text = styled.dd`
    @media (max-width: 700px) {
      font-size: 11px !important;
    }
  `
  const main = (
    <>
      <GoHome {...{ lang }} />
      <Header>
        <Title>{lang === 'it' ? 'Contatti' : 'Contact'}</Title>
      </Header>
      <Content>
        <p style={{ marginTop: '50px' }}>
          <a href="mailto: andrea.martines@gmail.com">
            andrea.martines@gmail.com
          </a>
        </p>
        <p>
          +39 349 7569806
        </p>
      </Content>
    </>
  )

  return (
    <Layout
      title={
        lang === 'it'
          ? 'Contatti - Andrea Martines'
          : 'Contact - Andrea Martines'
      }
      description=""
      otherLangSlug={lang === 'it' ? "contact" : "contatti"}
    >
      <Page {...{ side }} {...{ main }} />
    </Layout>
  )
}
export default Contact
